/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

@use '../mixins/_mat-button' as button;

@mixin gmcInputList() {
  .light-theme {
    .gmc-input-list__index {
      color: white;
    }
  }
  .dark-theme {
    .gmc-input-list__index {
      color: rgba(0, 0, 0, 0.87);
    }
  }

  // TODO CC-2858: Use this styles for dedicated component or remove them after implementation
  .gmc-input-list {
    list-style: none;
    margin: 12px 0;
    padding: 0;
    max-height: 100%;
    overflow: auto;

    &__item {
      display: flex;
      align-items: flex-start;
    }

    &__index {
      --size: 30px;
      background: var(--primary-color);
      display: inline-block;
      text-align: center;
      height: var(--size);
      min-width: var(--size);
      line-height: var(--size);
      border-radius: calc(var(--size) / 2);
      font-size: 16px;
      margin: 18px 20px 0;
      box-sizing: border-box;
      padding: 0 0.25em;
    }

    &__controls {
      display: flex;
      flex: 1;
    }

    &__remove {
      @include button.mat-button-size(24px, 0);
      @include button.mat-button-margin();
    }

    &__form-field {
      margin-bottom: 13px;
      // Fixes validation error flash in Chromium-based browsers. Works OK in Firefox without the fix.
      transform: translateZ(1px);

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .gmc-input-list-add-button {
    &.mat-mdc-button-base {
      margin-left: 94px;
      align-self: flex-start;
    }
  }
}
