/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

@mixin dashedList() {
  ul.dashed-list {
    list-style-type: none;
    padding-inline-start: 8px;
  }
  ul.dashed-list > li {
    text-indent: -4px;
  }
  ul.dashed-list > li:before {
    content: '-';
    text-indent: -4px;
    padding-right: 8px;
  }
}
