/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

@mixin gmcTracing() {
  page-tracing {
    --trace-color-prefix-width: 4px;
  }

  .no-padding .mat-expansion-panel-body {
    padding: 0;
  }

  traces-table {
    .gmc-trace-name-header {
      &.ag-header-cell {
        padding-left: calc(var(--ag-cell-horizontal-padding) * 2 + var(--trace-color-prefix-width));
      }
    }
  }
}
