/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

@mixin font-h1() {
  font-size: 96px;
  font-weight: 300;
  line-height: 127px;
}

@mixin font-h2() {
  font-size: 60px;
  font-weight: 300;
  line-height: 79px;
}

@mixin font-h3() {
  font-size: 48px;
  font-weight: 400;
  line-height: 63px;
}

@mixin font-h4() {
  font-size: 34px;
  font-weight: 400;
  line-height: 45px;
}

@mixin font-h5() {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}

@mixin font-h6() {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
}

@mixin font-subtitle-1() {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

@mixin font-subtitle-2() {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

@mixin font-body-1() {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

@mixin font-body-2() {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@mixin font-button() {
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}

@mixin font-caption() {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

@mixin font-overline() {
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
}
