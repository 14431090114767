/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

// Pallettes generated with http://mcg.mbitson.com/#!?mcgpalette0=%2350c8fe&themename=mcgtheme

$pallette-blue: (
  50: #e2ebf4,
  100: #b6cee3,
  200: #86aed1,
  300: #558dbf,
  400: #3074b1,
  500: #0c5ca3,
  600: #0a549b,
  700: #084a91,
  800: #064188,
  900: #033077,
  A100: #a5c1ff,
  A200: #729fff,
  A400: #3f7cff,
  A700: #256aff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$pallette-light-blue: (
  50: #ebf4ff,
  100: #cee4ff,
  200: #add2ff,
  300: #8cc0ff,
  400: #73b3ff,
  500: #5aa5ff,
  600: #529dff,
  700: #4893ff,
  800: #3f8aff,
  900: #2e79ff,
  A100: #ffffff,
  A200: #ffffff,
  A400: #dae6ff,
  A700: #c0d5ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
