/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use '@angular/material' as mat;

// Note: Color palettes are generated from primary: #31628d, secondary: #00696e, tertiary: #6f528a, neutral: #74777c
$_palettes: (
  primary: (
    0: #000000,
    10: #001d33,
    20: #003354,
    25: #003e66,
    30: #114a73,
    35: #225680,
    40: #31628d,
    50: #4c7ba7,
    60: #6695c2,
    70: #81afdf,
    80: #9ccbfb,
    90: #cfe5ff,
    95: #e8f1ff,
    98: #f7f9ff,
    99: #fcfcff,
    100: #ffffff,
  ),
  secondary: (
    0: #000000,
    10: #002022,
    20: #003739,
    25: #004346,
    30: #004f53,
    35: #005c60,
    40: #00696e,
    50: #2e8287,
    60: #4d9da2,
    70: #69b8bd,
    80: #85d3d9,
    90: #a1f0f5,
    95: #c1fbff,
    98: #e7feff,
    99: #f3ffff,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #280c42,
    20: #3f2458,
    25: #4a2f64,
    30: #563a71,
    35: #62467d,
    40: #6f528a,
    50: #896ba4,
    60: #a484c0,
    70: #bf9edc,
    80: #dbb9f9,
    90: #f0dbff,
    95: #faecff,
    98: #fff7fe,
    99: #fffbff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    4: #0a0b0d,
    6: #0e1113,
    10: #181c20,
    12: #1c2024,
    17: #272b2f,
    20: #2d3135,
    22: #313539,
    24: #363a3e,
    25: #383c40,
    30: #44474c,
    35: #4f5357,
    40: #5b5f63,
    50: #74777c,
    60: #8e9196,
    70: #a9abb1,
    80: #c4c6cc,
    87: #d8dae0,
    90: #e0e2e8,
    92: #e6e8ee,
    94: #eceef3,
    95: #eff1f6,
    96: #f2f4f9,
    98: #f7f9ff,
    99: #fcfcff,
    100: #ffffff,
  ),
  neutral-variant: (
    0: #000000,
    10: #171c22,
    20: #2b3138,
    25: #363c43,
    30: #42474e,
    35: #4d535a,
    40: #595f66,
    50: #72777f,
    60: #8c9199,
    70: #a6acb4,
    80: #c2c7cf,
    90: #dee3ec,
    95: #ecf1fa,
    98: #f7f9ff,
    99: #fcfcff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes, neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$light-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: $_primary,
      tertiary: $_tertiary,
      use-system-variables: true,
      system-variables-prefix: md-sys-color,
    ),
    typography: (
      use-system-variables: true,
      system-variables-prefix: md-sys-typescale,
    ),
  )
);
$dark-theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      primary: $_primary,
      tertiary: $_tertiary,
      use-system-variables: true,
      system-variables-prefix: md-sys-color,
    ),
    typography: (
      use-system-variables: true,
      system-variables-prefix: md-sys-typescale,
    ),
  )
);
