/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

@mixin gmcTooltip() {
  $mat-tooltip-vertical-padding: 6px;
  $mat-tooltip-horizontal-padding: 8px;
  $mat-tooltip-max-width: 250px;
  $mat-tooltip-margin: 14px;

  // used in the following components:
  //  PageMonitoringDashboardComponent,
  //  WidgetNodesHeatMapComponent,
  //  WidgetRebalanceComponent,
  //  ChartLegendDialogComponent
  .mdc-like-tooltip {
    color: white;
    border-radius: 4px;
    max-width: $mat-tooltip-max-width;
    padding: $mat-tooltip-vertical-padding $mat-tooltip-horizontal-padding;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    overflow-wrap: break-word;
    background: var(--tooltip-bg) !important;
    transform: scale(1); // when scaling widget in some cases it turns out to be scale(0) which breaks tooltips
    margin: 0;
  }

  .mat-mdc-tooltip {
    --mdc-plain-tooltip-supporting-text-color: white;
    --mdc-plain-tooltip-container-color: var(--tooltip-bg) !important;
    --mdc-plain-tooltip-supporting-text-size: 14px;

    .mdc-tooltip__surface {
      padding: $mat-tooltip-vertical-padding $mat-tooltip-horizontal-padding;
      margin: $mat-tooltip-margin;
      max-width: $mat-tooltip-max-width;
    }
  }
}
