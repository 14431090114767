/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

@mixin gmcSnackbar() {
  .mat-mdc-snack-bar-container {
    --mdc-snackbar-container-color: var(--snackbar-container-color) !important;
    --mdc-snackbar-supporting-text-color: var(--snackbar-supporting-text-color) !important;
    --mat-snack-bar-button-color: var(--snackbar-supporting-text-color) !important;
  }
}
