/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

@mixin gmcPublic() {
  mat-card.gmc-entry-card {
    width: 100%;

    padding: 0;
    margin-top: 40px;
    overflow: hidden;

    mat-card-header {
      padding-top: 17px;
      background-color: var(--primary-color);
      color: white;
      justify-content: center;

      mat-card-title {
        font-size: 34px !important;
        font-weight: 400 !important;
        line-height: 45px !important;
        margin-bottom: 12px !important;
      }
    }

    mat-card-content {
      font-size: 14px;
    }
  }

  .dark-theme mat-card.gmc-entry-card mat-card-header {
    color: #000;
  }
}
