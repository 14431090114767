/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */
@use 'driver.js/dist/driver.min.css';

@mixin gmcTutorials() {
  $light-popover-bg: #ffffff;
  $light-popover-text: #1a3643;
  $light-button-bg: #00acfb;

  $dark-popover-bg: #1a3643;
  $dark-popover-text: #00acfb;
  $dark-button-bg: #ffffff;

  div#driver-highlighted-element-stage {
    transition-delay: 0.1s;
    background-color: transparent !important;
    background: transparent !important;
    z-index: unset !important;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: white;
      z-index: 3000;
      z-index: 100004 !important;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 8px;
      mix-blend-mode: overlay;
      box-sizing: border-box;
    }

    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      border: 2px solid $dark-popover-text;
      box-shadow: 0px 0px 30px rgba(#50c8fe, 0.5) inset;
      z-index: 100005 !important;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 8px;
      box-sizing: border-box;
    }
  }

  .driver-fix-stacking {
    z-index: 0 !important;
  }

  div#driver-popover-item {
    background: $dark-popover-bg;
    margin: 0;
    padding: 15px;
    border-radius: 8px;
    min-width: 250px;
    max-width: 300px;
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);

    .driver-popover-tip {
      &.bottom {
        border-top-color: $dark-popover-bg;
      }

      &.left {
        border-right-color: $dark-popover-bg;
      }

      &.right {
        border-left-color: $dark-popover-bg;
      }

      &.top {
        border-bottom-color: $dark-popover-bg;
      }
    }

    .driver-popover-footer {
      display: block;
      margin-top: 16px;

      button {
        border: none;
        cursor: pointer;
        outline: 0;
        background-color: $dark-popover-text;
        text-shadow: none;
        font-family: Roboto, 'Helvetica Neue', sans-serif;
        font-size: 14px;
        font-weight: 500;
        box-sizing: border-box;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        display: inline-block;
        white-space: nowrap;
        vertical-align: baseline;
        text-align: center;
        margin: 0;
        min-width: 48px;
        line-height: 36px;
        padding: 0 16px;
        border-radius: 4px;
        overflow: visible;
        transform: translate3d(0, 0, 0);
        transition:
          background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
          box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
        box-shadow:
          0px 3px 1px -2px rgb(0 0 0 / 15%),
          0px 2px 2px 0px rgb(0 0 0 / 11%),
          0px 1px 5px 0px rgb(0 0 0 / 8%);
        color: $dark-popover-bg;
      }

      button.driver-disabled {
        cursor: default;
        pointer-events: none;
        display: none !important;
      }

      .driver-close-btn {
        float: left;
        box-shadow: none;
        color: $dark-popover-text;
        background-color: transparent;
        padding: 0;
      }

      .driver-prev-btn {
        float: left;
        box-shadow: none;
        color: $dark-popover-text;
        background-color: transparent;
        padding: 0;
      }

      .driver-close-only-btn {
        float: right;
        color: $dark-popover-bg;
        box-shadow:
          0px 3px 1px -2px rgb(0 0 0 / 20%),
          0px 2px 2px 0px rgb(0 0 0 / 14%),
          0px 1px 5px 0px rgb(0 0 0 / 12%);
        background-color: $dark-button-bg;
        padding-left: 1rem;
        padding-right: 1rem;
      }

      .driver-btn-group {
        float: right;
      }
    }

    .driver-popover-title {
      font-size: 16px;
      font-weight: 600;
      font-family: 'Roboto', sans-serif;
      color: #ffffff;
    }

    .driver-popover-description {
      font-weight: normal;
      font-size: 16px;
      font-family: 'Roboto', sans-serif;
      color: #ffffff;
    }
  }
  .light-theme {
    div#driver-highlighted-element-stage {
      &:after {
        border: 2px solid $dark-popover-text;
        box-shadow: none;
      }
    }
    div#driver-popover-item {
      background: $light-popover-bg;

      .driver-popover-tip {
        &.bottom {
          border-top-color: $light-popover-bg;
        }

        &.left {
          border-right-color: $light-popover-bg;
        }

        &.right {
          border-left-color: $light-popover-bg;
        }

        &.top {
          border-bottom-color: $light-popover-bg;
        }
      }

      .driver-popover-footer {
        button {
          background-color: $light-button-bg;
          color: $light-popover-bg;
        }

        .driver-close-btn {
          color: $light-button-bg;
          background: transparent;
        }

        .driver-prev-btn {
          color: $light-button-bg;
          background: transparent;
        }

        .driver-close-only-btn {
          color: $light-popover-bg;
          background-color: $light-button-bg;
        }
      }

      .driver-popover-title {
        color: #1a3643;
      }

      .driver-popover-description {
        color: #1a3643;
      }
    }
  }
}
