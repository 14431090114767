/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

@use '@ag-grid-community/styles/ag-grid.css';
@use '@ag-grid-community/styles/ag-theme-material.css';

@use '../../../../styles/mixins/mat-icon';
@use './selection/selection.global.scss';

@mixin gmc-grid() {
  .mat-mdc-card,
  .mat-mdc-dialog-container,
  .mat-expansion-panel {
    ag-grid-angular.ag-theme-material {
      border-radius: 0;

      .ag-root-wrapper {
        border-radius: 0;
      }
    }
  }

  $row-height: 48px;
  $header-height: 58px;
  $default-padding: 16px;
  $background-color: #fff;

  .ag-theme-material {
    --ag-font-size: 16px;
    --ag-row-height: #{$row-height};
    --ag-header-height: #{$header-height};
    --ag-cell-horizontal-padding: #{$default-padding};
    --ag-foreground-color: rgba(0, 0, 0, 0.87);
    --ag-background-color: var(--md-sys-surface);
    --ag-border-radius: 4px;
    --ag-icon-size: 22px;
    --ag-material-primary-color: var(--md-sys-color-primary);
    --ag-material-accent-color: var(--md-sys-color-tertiary);
    --ag-header-cell-hover-background-color: rgba(255, 255, 255, 0.5);
    --ag-checkbox-checked-color: #008080;
    --ag-checkbox-unchecked-color: #008080;

    .ag-root-wrapper {
      border-radius: 4px;
    }

    // TODO: CC-4659 check if the problem still here after upgrading ag-grid
    .ag-center-cols-viewport {
      // removes unwanted scrollbars
      overflow-x: hidden;

      & > .ag-center-cols-container {
        min-width: 100%;
      }
    }

    .ag-has-focus .ag-cell-focus {
      border: none !important;
      background-color: #eee;
    }

    .ag-header {
      background-color: var(--header-cell-background-color);
    }

    .ag-header-cell {
      font-weight: 500;
      color: var(--foreground-font-color);
      border-color: var(--header-cell-background-color);

      .mat-icon,
      .ag-header-icon {
        color: inherit;
      }
    }

    .ag-cell {
      a:not(.mat-mdc-button-base) {
        color: var(--foreground-font-color);
        text-decoration: underline;

        &:hover {
          opacity: 0.6;
        }
      }

      // Use for cells where content should not clip, like controls with ripple
      &--overflow-visible {
        .ag-cell-wrapper .ag-cell-value {
          overflow: visible;
        }
      }

      &--full-width {
        .ag-cell-wrapper .ag-cell-value {
          flex: 1;
        }
      }
    }

    .ag-row.ag-row-selected,
    .ggcc-master-row-expanded {
      background-color: var(--selected-row-background) !important;
    }

    .ag-pinned-right-header,
    .ag-cell-first-right-pinned {
      border-left: none !important;
    }

    .ag-pinned-left-header,
    .ag-cell-last-left-pinned {
      border-right: none !important;
    }

    .ag-floating-top {
      z-index: 100; // floating rows can be shown with ag-overlay, and they should be above the overlay
      border-bottom: unset;
      overflow-y: hidden !important;
    }

    .ag-horizontal-left-spacer:not(.ag-scroller-corner),
    .ag-horizontal-right-spacer:not(.ag-scroller-corner) {
      border-color: transparent;
    }
  }

  .ag-overlay {
    padding-top: var(--ag-header-height);

    .ag-overlay-panel {
      padding: 20px;
    }

    .ag-overlay-wrapper > ng-component {
      display: contents;
    }
  }

  body .ag-popup .ag-tooltip {
    color: #fff;
    border-radius: 4px;
    max-width: 350px;
    padding: 6px 8px;
    white-space: pre-line;
    background: var(--tooltip-bg);
    border: none !important;
    box-shadow: none !important;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    font-size: 14px;
    transition: unset !important;
    -webkit-font-smoothing: none !important;
    overflow-wrap: break-word;
  }

  .light-theme {
    ag-grid-angular.ag-theme-material {
      --header-cell-background-color: var(--md-sys-color-secondary-container);
      --selected-row-background: rgba(170, 170, 170, 0.15);
    }
    .gmc-grid-row-number-cell {
      color: rgba(0, 0, 0, 0.87);
    }
  }

  .dark-theme {
    ag-grid-angular.ag-theme-material {
      --ag-background-color: var(--md-sys-surface);
      --ag-foreground-color: var(--foreground-font-color);
      --header-cell-background-color: var(--md-sys-color-secondary-container);
      --selected-row-background: rgba(79, 199, 255, 0.15);
      --ag-row-hover-color: transparent;
      --ag-header-cell-hover-background-color: rgba(255, 255, 255, 0.25);
      --ag-selected-row-background-color: var(--selected-row-background);

      .ag-has-focus .ag-cell-focus {
        background-color: #383838;
      }
      .ag-overlay {
        color: var(--foreground-font-color);
      }
      .ag-root-wrapper {
        --ag-border-color: #092635;

        .ag-header-cell {
          color: var(--foreground-font-color);
        }
        .ag-row {
          border-color: var(--ag-border-color);

          &-hover:not(.ag-row-selected, .ggcc-master-row-expanded) {
            background-color: var(--selected-row-background) !important;
          }
        }
        .ag-paging-panel {
          background-color: var(--panel-background-color) !important;
          color: var(--foreground-font-color) !important;
          border-color: var(--ag-border-color) !important;
        }
      }
      .ag-dnd-ghost {
        color: #fff;
        background-color: #383838;

        .ag-icon {
          color: #fff;
        }
      }
      .ag-overlay-loading-center {
        background-color: #888;
      }
      .ag-theme-material .ag-icon-checkbox-checked,
      .ag-theme-material .ag-icon-checkbox-indeterminate {
        color: var(--primary-color) !important;
      }
    }

    .gmc-grid-row-number-cell {
      color: white;
    }
  }

  .ag-right-aligned-header {
    .ag-cell-label-container {
      flex-direction: row-reverse;
    }
    .ag-header-cell-label {
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  .ag-grid-text-center {
    text-align: center;

    .ag-header-cell-label,
    .ag-cell-wrapper {
      justify-content: center;
    }
  }

  // If you need buttons in grid overlays
  ag-grid-angular.enable-overlay-pointer-events {
    .ag-overlay,
    .ag-overlay-panel {
      pointer-events: all;
      user-select: text;
    }
  }

  // Fixes short cell vertical alignment
  .ag-cell-wrapper {
    height: 100%;
  }

  .gmc-grid-no-header-cell-hover {
    background-color: var(--header-cell-background-color) !important;
    transition: unset !important;
  }

  .gmc-grid-row-number-cell {
    opacity: 0.5;
  }
}
