/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

@mixin gmcMatStepper() {
  .mat-stepper-horizontal {
    --stepper-icon-background-color: #999;
    --stepper-icon-background-color-active: var(--primary-color);

    &.step-content-full-height {
      @for $i from 1 through 4 {
        &--#{$i} {
          .mat-horizontal-stepper-content[aria-expanded='true']:nth-child(#{$i}) {
            height: 100%;
          }
        }
      }
    }

    &.highlight-selected-only {
      .mat-step-header {
        .mat-step-icon {
          &,
          &.mat-step-icon-state-done,
          &.mat-step-icon-state-edit {
            color: var(--stepper-icon-color);
            background-color: var(--stepper-icon-background-color);
          }
        }

        .mat-step-icon-selected {
          background-color: var(--stepper-icon-background-color-active);
          color: var(--stepper-icon-color-active);
        }
      }
    }
  }

  .light-theme {
    .mat-stepper-horizontal {
      --stepper-icon-color-active: white;
    }
  }
  .dark-theme {
    .mat-stepper-horizontal {
      --stepper-icon-color-active: rgba(0, 0, 0, 0.87);
    }
  }
}
