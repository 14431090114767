/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

@mixin mat-icon-default() {
  font-size: 24px;
  width: 24px;
  height: 24px;
}

@mixin mat-icon-20() {
  font-size: 20px;
  width: 20px;
  height: 20px;
}

@mixin mat-icon-size($size) {
  font-size: $size;
  width: $size;
  height: $size;
}

@mixin mat-icon-inherit() {
  font-size: inherit;
  width: inherit;
  height: inherit;
}
