/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

@use './mat-dialog' as gmcDialog;
@use './mat-card' as gmcCard;
@use './screen-title' as screenTitle;
@use './scrollbar-styles' as scrollbarStyles;
@use './mat-expansion-panel' as expansionPanel;
@use './input-list' as inputList;
@use './mat-stepper' as gmcMatStepper;
@use './font-styles' as fontStyles;
@use './mat-tooltip' as gmcTooltip;
@use './overlay' as overlay;
@use './dashed-list' as dashedList;
@use './mat-checkbox' as gmcCheckbox;
@use './mat-radio' as gmcRadio;
@use './mat-snackbar' as gmcSnackbar;

@mixin gmcLayout() {
  @include fontStyles.gmcFontStyles();
  @include gmcCard.gmcCard();
  @include screenTitle.gmc-screen-title();
  @include scrollbarStyles.gmcScrollbars();
  @include expansionPanel.gmcExpansionPanel();
  @include inputList.gmcInputList();
  @include gmcDialog.gmcDialog();
  @include gmcMatStepper.gmcMatStepper();
  @include gmcTooltip.gmcTooltip();
  @include overlay.gmcOverlay();
  @include dashedList.dashedList();
  @include gmcCheckbox.gmcCheckbox();
  @include gmcRadio.gmcRadio();
  @include gmcSnackbar.gmcSnackbar();
}
