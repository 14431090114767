/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

@use 'sass:map';
@use 'sass:color' as color;
@use '@angular/material' as mat;
@use './styles/colors' as colors;
// Plus imports for other components in your app.

@mixin mat-icon-colors($focused, $unfocused, $inactive, $default) {
  .mat-mdc-menu-item {
    .mat-icon-no-color {
      color: $default;
    }
  }

  .mat-icon {
    color: $unfocused;
  }
  mat-icon[color='accent'] {
    color: var(--md-sys-color-tertiary) !important;
  }

  *:disabled,
  .disabled,
  [disabled] {
    .mat-icon {
      color: $inactive;
    }
  }

  button:not(.mat-mdc-menu-item, .no-focused-icon-color),
  a:not(.mat-mdc-menu-item, .no-focused-icon-color) {
    &:focus {
      .mat-icon {
        color: $focused;
      }
    }
  }

  button {
    &.mat-mdc-mini-fab,
    &.mat-mdc-fab {
      .mat-icon {
        color: inherit !important;
      }
    }
  }

  .mat-calendar-previous-button::after,
  .mat-calendar-next-button::after {
    color: $default;
  }
}

@mixin form-controls($overlay-opacity) {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: var(--input-background-color) !important;
  }
  .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
    opacity: $overlay-opacity;
  }
  // https://github.com/angular/components/issues/27337
  @supports selector(:has(*)) {
    mat-form-field:has(input:-webkit-autofill) .mdc-text-field {
      background-color: var(--input-background-color-autofilled) !important;
    }
    .mat-mdc-form-field-infix {
      input {
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-background-clip: text !important;
          -webkit-text-fill-color: var(--mat-select-enabled-trigger-text-color);
        }
      }
    }
  }
}
