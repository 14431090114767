/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

@mixin gmcDialog() {
  .mat-mdc-dialog-container {
    --mdc-dialog-supporting-text-color: var(--foreground-font-color) !important;
    --mdc-dialog-supporting-text-line-height: normal;
    --mdc-dialog-supporting-text-tracking: normal;
    --mdc-dialog-supporting-text-size: 16px;
    --mdc-dialog-supporting-text-weight: 400;

    .mat-mdc-dialog-content.mdc-dialog__content {
      padding-bottom: 0;
    }
  }

  .mat-mdc-dialog-content,
  .mat-mdc-dialog-title {
    word-break: break-word;

    .gmc-value-list--separated {
      margin-bottom: 12px;
    }
  }

  .mat-mdc-dialog-title {
    &.mdc-dialog__title {
      padding: 24px 24px 20px;
      &::before {
        height: 0;
      }
    }
    &--centered {
      &.mat-mdc-dialog-title {
        display: flex;
        align-items: center;
      }
    }
  }

  .mat-mdc-dialog-actions {
    justify-content: flex-end !important;
    padding: 0 24px !important;
    margin: 8px 0 !important;

    button[mat-button]:not([color]):not([disabled]) {
      --mdc-text-button-label-text-color: var(--primary-color);
      --mat-text-button-state-layer-color: var(--primary-color);
      --mat-text-button-ripple-color: rgba(12, 92, 163, 0.1);
    }
  }

  .dark-theme {
    .mat-mdc-dialog-actions {
      button[mat-button]:not([color]):not([disabled]) {
        --mat-text-button-ripple-color: rgba(80, 200, 254, 0.1); // dark theme primary color
      }
    }
  }
}
