/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

@use '../mixins/font-styles' as font;

@mixin gmcCard() {
  .mat-mdc-card {
    --card-padding: 16px;

    .mat-mdc-card-title {
      margin-bottom: 20px;
      @include font.font-h6();

      &--centered {
        display: flex;
        align-items: center;
      }

      &__actions {
        margin-left: auto;
        padding-left: 16px;

        & > *:not(:first-child) {
          margin-left: 8px;
        }
      }
    }

    .mdc-card__actions {
      padding: var(--card-padding); // Restores the pre-15 padding
    }
  }
}
