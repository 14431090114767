/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

@use '../mixins/font-styles' as font;

@mixin gmcFontStyles() {
  // class names are exact the same that can be inspected in gallery mocks
  .gmc-header-h1 {
    @include font.font-h1();
  }

  .gmc-header-h2 {
    @include font.font-h2();
  }

  .gmc-header-h3 {
    @include font.font-h3();
  }

  .gmc-header-h4 {
    @include font.font-h4();
  }

  .gmc-header-h5 {
    @include font.font-h5();
  }

  .gmc-header-h6 {
    @include font.font-h6();
  }

  .gmc-body2 {
    @include font.font-body-2();
  }

  .gmc-caption {
    @include font.font-caption();
  }

  .gmc-subtitle1 {
    @include font.font-subtitle-1();
  }
}
