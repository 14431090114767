/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

@mixin mat-button-size($size, $padding) {
  &.mat-mdc-icon-button.mat-mdc-button-base {
    width: $size !important;
    height: $size !important;
    padding: $padding !important;
  }
  .mat-mdc-button-touch-target {
    width: $size !important;
    height: $size !important;
  }
}

@mixin mat-button-margin() {
  &.mat-mdc-icon-button.mat-mdc-button-base {
    order: -1;
    margin-top: 21px;
  }
}
