/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;
@use 'm3-theme' as theme;
@use 'theme' as gmcTheme;
@use 'ngx-virtual-select-field/theme' as ngx-virtual-select-field;
@use './app/common/modules/gmc-grid/gmc-grid.global.scss' as grid;
@use './app/common/modules/popover/popover.global' as gmcPopover;
@use './app/tracing/tracing.global.scss' as gmcTracing;
@use './app/public/public.global' as gmcPublic;
@use 'styles/tutorials' as gmcTutorials;
@use './styles/layout/index' as gmcLayout;

@include mat.elevation-classes();
@include mat.app-background();
@include grid.gmc-grid();
@include gmcPopover.gmc-popover();
@include gmcTracing.gmcTracing();
@include gmcPublic.gmcPublic();
@include gmcLayout.gmcLayout();
@include gmcTutorials.gmcTutorials();

@include ngx-virtual-select-field.inherit-material-theme();

body {
  margin: 0;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
:root {
  @include mat.all-component-themes(theme.$light-theme);
  @include mat.system-level-typography(theme.$light-theme);

  & {
    --grid-gap: 10px;
    --tracing-tooltip-background-color: #e4e4e4;
    --primary-color: var(--mdc-filled-text-field-caret-color);
    --warn-color: var(--md-sys-color-error);
    --error-text-color: #fff;
    --status-level-green: #0dab6c;
    --status-level-neutral: rgba(0, 0, 0, 0.54);
    --status-level-red: var(--warn-color);
    --status-level-yellow: #fbbc06;
    --mat-card-header-height: 36px;
    --selector-background-color: #e1e1e1;
    --foreground-font-color: rgba(0, 0, 0, 0.87);
    --assistive-elements-font-color: rgba(0, 0, 0, 0.54);
    --tooltip-bg: rgba(97, 97, 97, 0.9);
    --default-border: 1px solid #a8a8a8;
    --default-border-radius: 4px;
    --logo-contrast-color: #ed1c24;
    --logo-background-color: white;
    --logo-background-url: url('/assets/images/gridgain-background-light.svg');
    --view-padding-top: 20px;

    --scrollbar-color: #0000001f;
    --scrollbar-active-color: #0000002c;
    --scrollbar-radius: 4px;

    --monaco-bg: #e9edf6;
    --monaco-white-bg: #fffffe;
    --monaco-text: #898989;
    --stepper-icon-color: white;
    --overlay-backdrop-color: rgba(0, 0, 0, 0.32);

    // TODO CC-4594: Use :root instead of body
    --chart-primary-color: #979797;
    --chart-secondary-color: #e1e1e1;
    --chart-grid-color: rgba(0, 0, 0, 0.1);
    --chart-gauge-layer-color: #ffffff;
    --chart-range-selection-background-color: rgba(0, 79, 179, 0.2);
    --chart-range-selection-border-color: #004fb3;

    --accordion-item-header-disabled-color: rgba(0, 0, 0, 0.38);

    --checkbox-radio-label-font-size: 16px;

    --snackbar-container-color: #323232;
    --snackbar-supporting-text-color: rgba(255, 255, 255, 0.7);
    --snack-bar-button-color: var(--snackbar-supporting-text-color);

    --input-background-color: rgba(0, 79, 179, 0.02);
    --input-background-color-autofilled: rgba(0, 79, 179, 0.1);
    --md-ref-palette-neutral-30: rgba(255, 255, 255, 0.3);

    --gridster-grid-color: rgba(0, 0, 0, 0.12);

    --mat-checkbox-label-text-size: 16px;
    --mat-radio-label-text-size: 16px;

    // M3
    --md-sys-color-background: rgb(255, 255, 255);
    --md-sys-color-on-background: #191c20;
    --md-sys-color-surface: #f8f9ff;
    --md-sys-color-surface-dim: #d8dae0;
    --md-sys-color-surface-bright: #f7f9ff;
    --md-sys-color-surface-container-lowest: #ffffff;
    --md-sys-color-surface-container-low: #f1f3f9;
    --md-sys-color-surface-container: #fff;
    --md-sys-color-surface-container-high: #e1e7f8;
    --md-sys-color-surface-container-highest: #dbe2f6;
    --md-sys-color-on-surface: #181c20;
    --md-sys-color-surface-variant: #eff1f9;
    --md-sys-color-on-surface-variant: #43474e;
    --md-sys-color-inverse-surface: #2d3135;
    --md-sys-color-inverse-on-surface: #eff1f6;
    --md-sys-color-outline: rgba(0, 0, 0, 0.12);
    --md-sys-color-outline-variant: #c2c7cf;
    --md-sys-color-shadow: #000000;
    --md-sys-color-scrim: #000000;
    --md-sys-color-surface-tint: #31628d;
    --md-sys-color-primary: #1b62a7;
    --md-sys-color-on-primary: #ffffff;
    --md-sys-color-primary-container: #cfe5ff;
    --md-sys-color-on-primary-container: #124a73;
    --md-sys-color-inverse-primary: #9ccbfb;
    --md-sys-color-secondary: #00696e;
    --md-sys-color-on-secondary: #ffffff;
    --md-sys-color-secondary-container: #a3e4e3;
    --md-sys-color-on-secondary-container: #004f53;
    --md-sys-color-tertiary: #ff9901;
    --md-sys-color-on-tertiary: #ffffff;
    --md-sys-color-tertiary-container: #f0dbff;
    --md-sys-color-on-tertiary-container: #563a70;
    --md-sys-color-error: #da3025;
    --md-sys-color-on-error: #ffffff;
    --md-sys-color-error-container: #ffdad6;
    --md-sys-color-on-error-container: #73332e;
    --md-sys-color-neutral: #74777c;
    --md-sys-color-neutral-variant: #72777e;
    --md-sys-color-primary-fixed: #cfe5ff;
    --md-sys-color-primary-fixed-dim: #9ccbfb;
    --md-sys-color-on-primary-fixed: #001d33;
    --md-sys-color-on-primary-fixed-variant: #124a73;
    --md-sys-color-secondary-fixed: #9cf0f6;
    --md-sys-color-secondary-fixed-dim: #80d4d9;
    --md-sys-color-on-secondary-fixed: #002022;
    --md-sys-color-on-secondary-fixed-variant: #004f53;
    --md-sys-color-tertiary-fixed: #f0dbff;
    --md-sys-color-tertiary-fixed-dim: #dbb9f9;
    --md-sys-color-on-tertiary-fixed: #280d42;
    --md-sys-color-on-tertiary-fixed-variant: #563a70;
    --md-sys-color-inverse-secondary: #80d4d9;
    --md-sys-color-inverse-tertiary: #dbb9f9;
    --md-sys-color-inverse-error: #ffb4ab;
    --md-sys-color-error-fixed: #ffdad6;
    --md-sys-color-error-fixed-dim: #ffb4ab;
    --md-sys-color-on-error-fixed: #3b0907;
    --md-sys-color-on-error-fixed-variant: #73332e;

    // M3 customization
    --mat-sidenav-container-divider-color: var(--md-sys-color-outline);
    --mat-sidenav-container-shape: 0;
    --mdc-filled-button-container-shape: var(--default-border-radius);
    --mdc-protected-button-container-shape: var(--default-border-radius);
    --mdc-elevated-card-container-shape: var(--default-border-radius);
    --mdc-outlined-card-container-shape: var(--default-border-radius);
    --mdc-dialog-container-shape: var(--default-border-radius);
    --mdc-outlined-button-container-shape: var(--default-border-radius);
    --mdc-text-button-container-shape: var(--default-border-radius);
    --mdc-elevated-card-container-elevation: 0px 0px 0px 1px var(--md-sys-color-outline);
    --mat-dialog-container-max-width: 80vw;
  }

  @include gmcTheme.mat-icon-colors(
    $focused: rgba(0, 0, 0, 0.87),
    $unfocused: rgba(0, 0, 0, 0.54),
    $inactive: rgba(0, 0, 0, 0.38),
    $default: rgba(0, 0, 0, 0.54)
  );
  @include gmcTheme.form-controls(0.04);
}

.mat-mdc-chip-highlighted {
  --mat-chip-selected-trailing-icon-color: color-mix(
    in srgb,
    var(--md-sys-color-on-error) 50%,
    rgba(255, 255, 255, 0) 50%
  );
  --mdc-chip-elevated-selected-container-color: var(--md-sys-color-error);
  --mdc-chip-selected-label-text-color: white;
}

button[color='primary'],
a[color='primary'] {
  --mdc-protected-button-container-color: var(--md-sys-color-primary);
  --mdc-protected-button-label-text-color: var(--md-sys-color-on-primary);
  --mat-protected-button-state-layer-color: white;
}
:root .mat-expansion-panel:not([class*='mat-elevation-z']) {
  --mat-expansion-container-shape: var(--default-border-radius);
  box-shadow: none;
  border: 1px solid var(--md-sys-color-outline);
}
.light-theme .mat-expansion-panel:not([class*='mat-elevation-z']) {
  --mat-expansion-container-background-color: var(--md-sys-color-background);
}
.light-theme mat-card.mat-mdc-card:not(.card-secondary) {
  --mdc-elevated-card-container-color: var(--md-sys-color-background);
}

.dark-theme {
  @include mat.all-component-colors(theme.$dark-theme);
  @include mat.system-level-typography(theme.$dark-theme);

  & {
    --tracing-tooltip-background-color: #212121;
    --panel-background-color: #616161;
    --selector-background-color: var(--md-sys-color-primary-container);
    --foreground-font-color: #e4eaf1;
    --assistive-elements-font-color: rgba(255, 255, 255, 0.7);
    --tooltip-bg: #113343;
    --status-level-neutral: #bdbdbd;
    --logo-contrast-color: white;
    --logo-background-color: #041619;
    --logo-background-url: url('/assets/images/gridgain-background-dark.svg');

    --scrollbar-color: #ffffff29;
    --scrollbar-active-color: #ffffff41;

    --monaco-bg: #030f11; // TODO: GG-26000 Remove when Monaco preloading is done
    --monaco-white-bg: var(--monaco-bg); // TODO: GG-26000 Remove when Monaco preloading is done
    --monaco-text: #bababa;

    --stepper-icon-color: rgba(0, 0, 0, 0.87);
    --overlay-backdrop-color: #000c1380;

    --chart-primary-color: #008080;
    --chart-secondary-color: #113343;
    --chart-grid-color: #092635;
    --chart-gauge-layer-color: #18222e;
    --chart-range-selection-background-color: rgba(80, 200, 254, 0.3);
    --chart-range-selection-border-color: #50c8fe;

    --accordion-item-header-disabled-color: rgba(255, 255, 255, 0.5);

    --snackbar-container-color: #fafafa;
    --snackbar-supporting-text-color: rgba(0, 0, 0, 0.87);

    --input-background-color: rgba(80, 200, 254, 0.02);
    --input-background-color-autofilled: rgba(80, 200, 254, 0.1);
    --md-ref-palette-neutral-30: rgba(255, 255, 255, 0.3);

    --mat-icon-button-state-layer-color: #e4eaf1;
    --mat-text-button-state-layer-color: #e4eaf1;
    --mdc-text-button-label-text-color: #e4eaf1;

    --mat-sidenav-content-background-color: #111821;
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--mat-option-selected-state-label-text-color);

    --gridster-grid-color: rgba(255, 255, 255, 0.12);

    // M3
    --md-sys-color-background: #101418;
    --md-sys-color-on-background: #e0e2e8;
    --md-sys-color-surface: #18222e;
    --md-sys-color-surface-dim: #101418;
    --md-sys-color-surface-bright: #36393e;
    --md-sys-color-surface-container-lowest: #0b0f12;
    --md-sys-color-surface-container-low: var(--md-sys-color-surface);
    --md-sys-color-surface-container: #25303f;
    --md-sys-color-surface-container-high: #272a2f;
    --md-sys-color-surface-container-highest: #323539;
    --md-sys-color-on-surface: #e0e2e8;
    --md-sys-color-surface-variant: rgba(80, 200, 254, 0.02);
    --md-sys-color-on-surface-variant: #c2c7cf;
    --md-sys-color-inverse-surface: #e0e2e8;
    --md-sys-color-inverse-on-surface: #2d3135;
    --md-sys-color-outline: rgba(255, 255, 255, 0.12);
    --md-sys-color-outline-variant: #42474e;
    --md-sys-color-shadow: #000000;
    --md-sys-color-scrim: #000000;
    --md-sys-color-surface-tint: #9ccbfb;
    --md-sys-color-primary: #5398eb;
    --md-sys-color-on-primary: #003354;
    --md-sys-color-primary-container: #124a73;
    --md-sys-color-on-primary-container: #cfe5ff;
    --md-sys-color-inverse-primary: #31628d;
    --md-sys-color-secondary: #80d4d9;
    --md-sys-color-on-secondary: #003739;
    --md-sys-color-secondary-container: #004f53;
    --md-sys-color-on-secondary-container: #9cf0f6;
    --md-sys-color-tertiary: #ff9901;
    --md-sys-color-on-tertiary: #3f2458;
    --md-sys-color-tertiary-container: #563a70;
    --md-sys-color-on-tertiary-container: #f0dbff;
    --md-sys-color-error: #da3025;
    --md-sys-color-on-error: #561e19;
    --md-sys-color-error-container: #73332e;
    --md-sys-color-on-error-container: #ffdad6;
    --md-sys-color-neutral: #74777c;
    --md-sys-color-neutral-variant: #72777e;
    --md-sys-color-primary-fixed: #cfe5ff;
    --md-sys-color-primary-fixed-dim: #9ccbfb;
    --md-sys-color-on-primary-fixed: #001d33;
    --md-sys-color-on-primary-fixed-variant: #124a73;
    --md-sys-color-secondary-fixed: #9cf0f6;
    --md-sys-color-secondary-fixed-dim: #80d4d9;
    --md-sys-color-on-secondary-fixed: #002022;
    --md-sys-color-on-secondary-fixed-variant: #004f53;
    --md-sys-color-tertiary-fixed: #f0dbff;
    --md-sys-color-tertiary-fixed-dim: #dbb9f9;
    --md-sys-color-on-tertiary-fixed: #280d42;
    --md-sys-color-on-tertiary-fixed-variant: #563a70;
    --md-sys-color-inverse-secondary: #00696e;
    --md-sys-color-inverse-tertiary: #6f528a;
    --md-sys-color-inverse-error: #904a43;
    --md-sys-color-error-fixed: #ffdad6;
    --md-sys-color-error-fixed-dim: #ffb4ab;
    --md-sys-color-on-error-fixed: #3b0907;
    --md-sys-color-on-error-fixed-variant: #73332e;

    // M3
    --mdc-elevated-card-container-elevation: 0px 0px 0px 1px var(--md-sys-color-outline);
  }

  @include gmcTheme.mat-icon-colors(
    $focused: var(--mat-menu-item-label-text-color),
    $unfocused: rgba(255, 255, 255, 0.7),
    $inactive: rgba(255, 255, 255, 0.5),
    $default: var(--mat-menu-item-label-text-color)
  );
}

a:not([class*='mat-']) {
  color: var(--primary-color);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.snackbar-error {
  --snackbar-container-color: var(--warn-color);
  --snackbar-supporting-text-color: white;

  max-height: 295px;
  overflow: hidden;
  -webkit-line-clamp: 3;

  .mdc-snackbar__label {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    mask-image: linear-gradient(to bottom, white 0%, white 75px, transparent 76px);
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
    mask-position: center;
  }
}

// https://github.com/angular/components/issues/11765#issuecomment-447991348
.ng-animating .control-expansion-animation mat-accordion mat-expansion-panel mat-expansion-panel-header {
  height: 48px;
}
.ng-animating .control-expansion-animation mat-accordion mat-expansion-panel div.mat-expansion-panel-content {
  height: 0px;
  visibility: hidden;
}

.theme__card-background {
  background: #fff !important;
  color: #424242;
}
.dark-theme {
  .theme__card-background {
    background: #18222e !important;
    color: #e4eaf1;
  }
}

button[mat-icon-button] .mat-button-wrapper {
  display: flex;
  justify-content: center;
}

// Adds a bottom border to menu item groups
.menu-section {
  &:not(:first-child):not(:empty)::before,
  &:not(:empty) ~ &:empty + &:not(:empty)::before {
    content: '';
    display: block;
    margin: 8px 0;
    border-top-width: 1px;
    border-top-style: solid;
    color: var(--md-sys-color-outline);
  }

  &:empty + &:not(:empty)::before {
    display: none;
  }
}

.mat-mdc-snack-bar-container.snackbar-notification {
  max-width: 80vw;
}

// Fixes unexpected wrapping to new line
[mattextsuffix] {
  white-space: nowrap;
}
// https://github.com/angular/components/issues/22692
.mat-form-field-disabled [mattextsuffix] {
  opacity: 0.38;
}

mat-form-field.mat-form-field-custom-height,
.mat-form-field-custom-height mat-form-field {
  --default-height: 56px;
  --default-padding: 32px;
  .mat-mdc-form-field-infix {
    --padding-vertical: calc(
      (var(--mat-form-field-custom-height) - var(--default-height) + var(--default-padding)) / 2
    );
    padding-top: var(--padding-vertical) !important;
    padding-bottom: var(--padding-vertical) !important;
    min-height: initial;
  }
  .mdc-floating-label {
    transform: translateY(calc(-50% - ((var(--default-height) - var(--mat-form-field-custom-height)) / 2)));
  }
  .mat-mdc-form-field-icon-prefix > .mat-icon,
  .mat-mdc-form-field-icon-suffix > .mat-icon {
    --default-padding: 12px;
    --height-delta: calc((var(--default-height) - var(--mat-form-field-custom-height)));
    --new-vertical-padding: calc(var(--default-padding) - (var(--height-delta) * 0.5));
    padding: var(--new-vertical-padding) var(--default-padding);
  }
}

mat-form-field.subscript-min-height,
.subscript-min-height mat-form-field {
  .mat-mdc-form-field-subscript-wrapper {
    min-height: var(--subscript-min-height, 22px);
  }
}

mat-form-field.mat-form-field-subscript-hidden {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

button[mat-icon-button].mat-icon-button-custom-height,
.mat-icon-button-custom-height button[mat-icon-button] {
  --default-icon-button-size: 48px;
  --default-icon-button-padding: 12px;

  --custom-icon-button-size: var(--mat-icon-button-custom-height);
  --custom-icon-button-padding: calc(
    var(--default-icon-button-padding) - ((var(--default-icon-button-size) - var(--custom-icon-button-size)) / 2)
  );

  --mdc-icon-button-state-layer-size: var(--custom-icon-button-size) !important;
  padding: var(--custom-icon-button-padding) !important;
}

// Same max width as in legacy components
.mat-select-panel-max-width-285 {
  max-width: 285px;
}

hint {
  mat-expansion-panel:not(.mat-expanded) {
    .mat-expansion-indicator {
      margin-bottom: 5px;
    }
  }
}

.multiline {
  white-space: pre-line;
}

// Similar effect can be achieved with <mat-divider>, but this would require more effort to handle the markup changes
// https://m3.material.io/components/tabs/guidelines#44df6db2-8287-4d92-b2fe-17dab12340a4
[mat-tab-nav-bar],
mat-tab-header {
  border-bottom: 1px solid var(--md-sys-color-outline);
}

// Restore pre-MDC letter spacing
.mat-mdc-tab-header,
.mat-mdc-tab {
  --mat-tab-header-label-text-tracking: normal;
}

ngx-monaco-editor {
  .monaco-editor .monaco-scrollable-element > .scrollbar > .slider {
    background-color: var(--scrollbar-color);
    border-radius: var(--scrollbar-radius);

    &:active {
      background-color: var(--scrollbar-active-color);
    }
  }

  background: var(--monaco-bg);

  .monaco-editor .monaco-scrollable-element .scrollbar.horizontal,
  .monaco-editor .decorationsOverviewRuler,
  .monaco-editor .monaco-scrollable-element .scrollbar.vertical .arrow-background {
    background: var(--monaco-bg);
  }

  &.white-theme {
    background: var(--monaco-white-bg);

    .monaco-editor .monaco-scrollable-element .scrollbar.horizontal,
    .monaco-editor .decorationsOverviewRuler,
    .monaco-editor .monaco-scrollable-element .scrollbar.vertical .arrow-background,
    .monaco-editor .margin,
    .monaco-editor,
    .monaco-editor-background,
    .monaco-editor .inputarea.ime-input {
      background: var(--monaco-white-bg);
    }
  }
}

.loader-wrapper {
  position: absolute;
  top: 40%;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;

  img {
    height: 100%;
  }

  .loading-bar-wrapper {
    background-color: lightgrey;
    height: 2px;
    margin: 1em;
    overflow: hidden;
    position: relative;
    width: 12em;
  }

  .loading-bar {
    animation: side2side 2s ease-in-out infinite;
    background-color: #ee2b27;
    height: 100%;
    position: absolute;
    width: 50%;
  }

  @media (prefers-color-scheme: dark) {
    .loading-bar-wrapper {
      // background-color: color.scale(map.get($gmc-primary-dark, 500), $alpha: -85%);
    }
    .loading-bar {
      background-color: white;
    }
  }

  @keyframes side2side {
    0%,
    100% {
      transform: translateX(-50%);
    }
    50% {
      transform: translateX(150%);
    }
  }

  .center {
    left: 50%;
    margin: 0;
    position: absolute;
    top: 150%;
    transform: translate(-50%, -50%);
  }
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src:
    local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(assets/fonts/material-icons-regular.woff2) format('woff2'),
    url(assets/fonts/material-icons-regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
}

.gmc-value-list {
  --label-color: rgba(0, 0, 0, 0.54);
  margin: 0;

  &--separated {
    padding-bottom: 12px;
    border-bottom: 1px solid var(--md-sys-color-outline);
  }

  &--no-colons {
    & > dt::after {
      content: '' !important;
    }
  }

  & > dt,
  & > dd {
    display: inline;
  }

  & > dt {
    margin: 0 4px 0 0;
    color: var(--label-color);

    &::after {
      content: ':';
    }
  }

  & > dd {
    margin: 0;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}

.dark-theme dl.gmc-value-list {
  --label-color: rgba(255, 255, 255, 0.7);
}

// Default form styles
// https://gallery.io/projects/MCHbtQVoQ2HCZTy-HcccL3iB/files/MCEJu8Y2hyDScRGIWiiarfwXKbsEqHE49ig

.gmc-form {
  // Same as in the existing non-global forms
  --form-gap: 8px;
  // Same as in the guideline, not exactly 2x, we might want to adjust 1x gap to 10px
  --form-gap-2x: 20px;

  & > *:not(:last-child):not(legend) {
    margin-bottom: var(--form-gap);
  }

  .mat-mdc-form-field {
    width: 100%;
  }

  // According to the guideline, non-text controls (i.e. where user can't type values in) should have 2x gaps,
  // otherwise form would look too cramped
  mat-checkbox,
  .gmc-value-list {
    &:not(:last-child) {
      display: block;
      margin-bottom: var(--form-gap-2x);
    }
  }

  fieldset {
    margin: var(--form-gap-2x) 0;
    padding: 0;
    border: none;

    legend {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      padding: 0 0 var(--form-gap-2x) 0 !important;
    }
  }
}

.mat-mdc-form-field .mat-mdc-form-field-icon-suffix {
  color: var(--assistive-elements-font-color);
}

ngx-monaco-editor .monaco-editor .scroll-decoration {
  box-shadow: none;
}

.mat-toolbar .mat-icon {
  color: inherit;
}

.mat-mdc-chip {
  font-weight: 400;
}

as-split {
  &.with-inner-shadows > .as-split-area:not(.as-hidden),
  .as-split-area:not(.as-hidden).with-inner-shadows,
  .as-split-area:not(.as-hidden) .with-inner-shadows {
    box-sizing: border-box;
    padding: 1px 1px 2px;
  }

  &:not(.tiny) {
    > .as-split-gutter {
      background: transparent !important;

      > .as-split-gutter-icon {
        background-image: none !important;
        position: relative;

        &:hover {
          &:before {
            content: '';
            position: absolute;
            width: 200%;
            height: 200%;
            top: -50%;
            left: -50%;
            background-position: center center;
            background-repeat: no-repeat;
            background-image: url('/assets/images/resize.svg');
          }
        }
      }
    }
  }
  &.tiny {
    > .as-split-gutter {
      background-color: var(--md-sys-color-outline) !important;
      position: relative;

      > .as-split-gutter-icon {
        background-image: none !important;
        opacity: 0;
        position: absolute;
      }
    }

    &.as-horizontal {
      .as-split-gutter-icon {
        width: 17px !important;
        left: -8px;
        right: 8px;
      }
    }
    &.as-vertical {
      .as-split-gutter-icon {
        height: 17px !important;
        top: -8px;
        bottom: 8px;
      }
    }
  }
}

.dark-theme as-split:not(.tiny) > .as-split-gutter > .as-split-gutter-icon:hover:before {
  background-image: url('/assets/images/resize.dark.svg');
}
as-split:not(.tiny).as-horizontal > .as-split-gutter > .as-split-gutter-icon:hover:before {
  width: 400%;
  left: -150%;
  transform: rotate(90deg);
}
.material-emphasis-medium {
  opacity: 0.6;
}
.material-emphasis-high {
  opacity: 1;
}

/* Removes arrows from number input */
input[type='number'] {
  /* Firefox */
  -moz-appearance: textfield;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

// TODO: Temporal solution, should be removed in GG-30782
.chip-list-with-scroll-container {
  .mdc-evolution-chip-set__chips {
    max-height: 120px;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0;
  }

  &--2-lines {
    .mdc-evolution-chip-set__chips {
      max-height: 80px;
      align-items: flex-start;
    }
  }
}

.inline-code {
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  border-radius: 2px;
  padding-left: 0.25em;
  padding-right: 0.25em;
  color: black;
  background-color: #e9edf6;
}

.dark-theme .inline-code {
  color: #e4eaf1;
  background-color: rgba(79, 199, 255, 0.15);
}

// Preserve Angular Material 13 behavior, hide tooltip when pointer leaves the trigger
// TODO CC-6232: Remove the hack
.mat-tooltip-panel {
  pointer-events: none !important;
}

// Restores the old behavior from Angular 13
a[disabled] {
  &:not([disabled='false']) {
    pointer-events: none;
  }
}
