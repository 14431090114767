/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

@mixin gmcExpansionPanel() {
  .mat-expansion-panel {
    &.gmc-banners-panel {
      z-index: 1000;
      border-radius: 0;

      .mat-expansion-panel-header {
        padding: 0;
        height: var(--banner-height);

        &.mat-expanded {
          height: inherit;
        }
      }

      .mat-expansion-indicator {
        position: absolute;
        right: 30px;
      }

      .mat-expansion-panel-body {
        padding: 0;

        .mat-toolbar {
          margin-top: 1px;
        }
      }
    }
  }
}
